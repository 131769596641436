<template>
    <div class="header_box" ref="element">
        <div class="xufei-wrap" v-if="expireTime < 15 && isShowXufei">
            <div class="xufei-wrap-con">
                <el-icon color="#FAAD14"><WarningFilled /></el-icon>
                <span v-if="expireTime > 0" class="marg_X_8"
                    >尊敬的用户：感谢您使用星云系统，您当前的服务就要到期了，请及时购买/续费</span
                >
                <span v-else class="marg_X_8">尊敬的用户：感谢您使用星云系统，您当前的服务已到期，请及时购买/续费</span>
                <span style="color: #0082ff; cursor: pointer" @click="renewNow">立即续费</span>
            </div>
            <div class="xufei-wrap-close" @click="isShowXufei = false">
                <el-icon><Close /></el-icon>
            </div>
        </div>
        <ul class="menu-box">
            <div class="text_overflow_1 font_size_18 left_top">
                <div class="d_flex_aItc d_flex allH_100">
                    <Aside class="step_1" :heightMax="heightMax"></Aside>
                    <img
                        src="@/assets/images/login/logo2.png"
                        class="marg_bottom_4 ding_X_16 border_right"
                        style="min-width: 120px"
                    />
                    <span class="font_size_20 title_left_w_2_f ding_left_16 font_weight_600">
                        {{ userInfo.dot || userInfo.dotName || "网点名称" }}
                    </span>
                </div>
                <el-tooltip
                    content="当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费"
                    :disabled="expireTime > -1"
                >
                    <el-select
                        v-model="state1"
                        @visible-change="focusSearch"
                        @change="changeSearch"
                        :filter-method="filterSearch"
                        :disabled="expireTime < 0"
                        placeholder="快捷搜索"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in pathList1"
                            :key="index"
                            :label="item.name"
                            :value="item.name"
                        />
                        <template #prefix>
                            <el-icon style="color: #0082ff; position: relative"><Search /></el-icon>
                        </template>
                    </el-select>
                </el-tooltip>
            </div>
            <div class="d_flex d_flex_aItc">
                <img src="@/assets/images/header/time.png" class="h_24 marg_right_8" />
                <span class="marg_right_16">
                    剩余
                    <span class="font_weight_bold font_size_16"> {{ expireTime < 0 ? 0 : expireTime }} </span>
                    天到期
                </span>
                <img
                    src="@/assets/images/menu/icon_sc.png"
                    class="cursor_pointer hover_ebf0f62f w_30 boxSizing_borderBox border_radius_2 ding_4"
                    @click="uploadPath = true"
                />
                <div>
                    <img
                        v-if="downloadIconBig"
                        src="@/assets/images/menu/icon_xz_big.gif"
                        class="marg_X_24 cursor_pointer hover_ebf0f62f border_radius_2 ding_4 w_30 boxSizing_borderBox"
                        @click="showDrawer('download')"
                    />
                    <img
                        v-else
                        src="@/assets/images/menu/icon_xz.png"
                        class="marg_X_24 cursor_pointer hover_ebf0f62f border_radius_2 ding_4 w_30 boxSizing_borderBox"
                        @click="showDrawer('download')"
                    />
                </div>
                <img
                    src="@/assets/images/menu/icon_xx.png"
                    class="cursor_pointer hover_ebf0f62f w_30 boxSizing_borderBox border_radius_2 ding_4"
                    @click="showDrawer('messages')"
                />
                <a href="https://ketang.y-open.com/" target="_blank">
                    <img
                        src="@/assets/images/menu/icon_sysc.png"
                        class="marg_X_24 hover_ebf0f62f border_radius_2 ding_4"
                    />
                </a>
                <el-popover placement="bottom-end" popper-style="padding:0" width="20%" trigger="click">
                    <template #reference>
                        <div class="d_flex d_flex_aItc cursor_pointer">
                            <img
                                v-if="['', null].includes(userInfo.filePath)"
                                class="allW_30"
                                style="max-width: 50px; border-radius: 50%"
                                src="@/assets/images/login/tp_1.png"
                            />
                            <img
                                v-else
                                :src="userInfo.filePath"
                                class="allW_30 text_overflow_1"
                                style="max-width: 50px; border-radius: 50%"
                            />
                            <span class="text_overflow_1" style="margin: 0 0 0 6px; max-width: 70px">
                                {{ userInfo.name || "姓名" }}
                            </span>
                            <el-icon class="font_size_20 marg_left_4"><ArrowDown /></el-icon>
                        </div>
                    </template>
                    <div>
                        <div class="flex flex-col-center personal_center_top">
                            <img
                                v-if="['', null].includes(userInfo.filePath)"
                                class="allW_20"
                                src="@/assets/images/login/tp_1.png"
                            />
                            <img v-else :src="userInfo.filePath" class="allW_30" />
                            <div class="marg_left_12 allW_100">
                                <p>
                                    <span class="text_overflow_1 font-size_18 font_weight_bold">
                                        {{ userInfo.name || "姓名" }}
                                    </span>
                                    <span style="max-width: 50px" class="text_overflow_1 text_regentGray">
                                        ({{ userInfo.code }})
                                    </span>
                                </p>
                                <span class="text_regentGray">
                                    所属网点: {{ userInfo.dot || userInfo.dotName || "网点名称" }}
                                </span>
                                <p class="d_flex_between d_flex">
                                    <span
                                        class="text_regentGray d_flex d_flex_between"
                                        :title="'到期时间:' + userInfo.expireTime"
                                        >到期时间:{{ userInfo.expireTime }}</span
                                    >
                                    <!-- <span class="text_primary cursor_pointer" @click="visible = true">我要续期</span> -->
                                    <span class="text_primary cursor_pointer" @click="renewNow">我要续期</span>
                                </p>
                            </div>
                        </div>
                        <div class="personal_center_item cursor_pointer" @click="userClick('editPwd')">
                            <span class="d_flex d_flex_aItc marg_left_16">
                                <img src="@/assets/images/header/xgmm.png" class="marg_right_16" width="20" />修改密码
                            </span>
                            <el-icon class="marg_right_16"><ArrowRight /></el-icon>
                        </div>
                        <div class="personal_center_item cursor_pointer" @click="clickBindWx">
                            <div class="d_flex d_flex_aItc marg_left_16">
                                <img src="@/assets/images/header/bdwx.png" class="marg_right_16" width="20" /><span>
                                    绑定微信
                                </span>
                                <span class="text_primary marg_left_8" v-if="userInfo.wxName">
                                    {{ userInfo.wxName }}
                                </span>
                            </div>
                            <el-link
                                type="danger"
                                class="marg_right_16"
                                v-if="userInfo.wxName"
                                @click.stop="deleteWxBlind(userInfo)"
                            >
                                解绑
                            </el-link>
                            <el-icon class="marg_right_16" v-else><ArrowRight /></el-icon>
                        </div>
                        <div class="personal_center_item cursor_pointer" @click="userClick('log')">
                            <span class="d_flex d_flex_aItc marg_left_16">
                                <img src="@/assets/images/header/czrz.png" class="marg_right_16" width="20" />操作日志
                            </span>
                            <el-icon class="marg_right_16"><ArrowRight /></el-icon>
                        </div>
                        <div class="personal_center_item cursor_pointer" @click="userClick('dotList')">
                            <span class="d_flex d_flex_aItc marg_left_16">
                                <img src="@/assets/images/header/qhwd.png" class="marg_right_16" width="20" />切换网点
                            </span>
                            <el-icon class="marg_right_16"><ArrowRight /></el-icon>
                        </div>
                        <div class="personal_center_item cursor_pointer" @click="userClick('payList')">
                            <span class="d_flex d_flex_aItc marg_left_16">
                                <img src="@/assets/images/header/czrz.png" class="marg_right_16" width="20" /> 支付列表
                            </span>
                            <el-icon class="marg_right_16"><ArrowRight /></el-icon>
                        </div>
                        <div @click="exit_clear" class="personal_center_bottom cursor_pointer">退出登录</div>
                    </div>
                </el-popover>
            </div>
        </ul>
        <div class="d_flex d_flex_aItc ding_X_24 border_bottom" style="height: 5.6vh; min-height: 50px">
            <mx-page-tabs :data="pageTabs" :value="$route.name"></mx-page-tabs>
        </div>
        <el-dialog v-model="uploadPath" title="常用数据录入" width="50%">
            <div class="upload_class marg_bottom_24">
                <div class="upload_class_item" @click="route('/bill/data/import')">
                    <div><img src="@/assets/images/menu/upload.png" alt="" /></div>
                    <p class="marg_Y_12">揽收数据导入</p>
                </div>
                <div class="upload_class_item" @click="route('/cost/import')">
                    <div><img src="@/assets/images/menu/upload.png" alt="" /></div>
                    <p class="marg_Y_12">总部对账数据导入</p>
                </div>
                <div class="upload_class_item" @click="route('/report-form/flow')">
                    <div><img src="@/assets/images/menu/upload.png" alt="" /></div>
                    <span style="color: #0082ff"></span>
                    <p class="marg_Y_12">日常收支登记</p>
                </div>
                <div class="upload_class_item" @click="route('/cost/import')">
                    <div><img src="@/assets/images/menu/upload.png" alt="" /></div>
                    <p class="marg_Y_12">总部成本明细导入</p>
                </div>
            </div>
        </el-dialog>
        <el-drawer v-model="download.path" :show-close="false" class="download_c">
            <el-tabs v-model="download.name" class="demo-tabs" @tab-change="handleTabChange">
                <el-tab-pane label="下载任务" :name="1">
                    <template v-if="download.list && download.list.length">
                        <div class="downloadContent">
                            <div class="downloadContent_pos">{{ tipsText }}</div>
                            <div
                                class="downloadContent_item"
                                :class="{ downloaded: row.isDownload == 1, marg_top_12: index }"
                                v-for="(row, index) in download.list"
                                :key="index"
                            >
                                <div class="downloadContent_item_left">
                                    <p
                                        class="text_overflow_1 downloadContent_item_left_title"
                                        :title="row.name + ' -- ' + row.fileName"
                                    >
                                        {{ row.name }} -- {{ row.fileName }}
                                    </p>
                                    <div class="font_size_12">
                                        <p>创建人:{{ row.createName }}</p>
                                        <p class="marg_top_12">创建时间:{{ row.createTime }}</p>
                                    </div>
                                </div>
                                <div class="downloadContent_item_right" @click="downloadFile(row)">
                                    <el-icon size="32px"
                                        ><Download v-if="!row.icon" /> <Loading v-else class="rotate"
                                    /></el-icon>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            style="
                                padding-top: 20px;
                                color: #999;
                                font-size: 14px;
                                text-align: center;
                                letter-spacing: 2px;
                            "
                        >
                            暂无数据...
                        </div>
                    </template>
                    <div class="d_flex d_flex_center" v-if="download.list && download.list.length">
                        <el-pagination
                            @size-change="handleSizeChangeD"
                            @current-change="getDownload"
                            :current-page="download.currentPage"
                            :page-size="download.pageSize"
                            :total="download.total"
                            layout="total, pager, jumper"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="工具下载" :name="2">
                    <div class="tool">
                        <div class="tool_left">
                            <div class="tool_item" @click="getImgList(null)">
                                <img src="@/assets/images/header/boss.png" alt="" />
                                <div>
                                    <p class="font_weight_bold">boss舱</p>
                                    <p class="marg_top_20">微信扫一扫</p>
                                </div>
                            </div>
                            <a href="https://www.pdfpai.com/" class="tool_item" target="_blank">
                                <img src="@/assets/images/header/pdf.png" alt="" />
                                <div>
                                    <p class="font_weight_bold">PDF工具箱</p>
                                    <p class="marg_top_20">点击跳转</p>
                                </div>
                            </a>
                            <a href="https://sunlogin.oray.com/" class="tool_item" target="_blank">
                                <img src="@/assets/images/header/xrk.png" alt="" />
                                <div>
                                    <p class="font_weight_bold">向日葵远程助手</p>
                                    <p class="marg_top_20">点击跳转</p>
                                </div>
                            </a>
                        </div>
                        <div class="tool_right">
                            <div class="tool_item" @click="getImgList('1')">
                                <img src="@/assets/images/header/ywy.png" alt="" />
                                <div>
                                    <p class="font_weight_bold">星云业务员端</p>
                                    <p class="marg_top_20">微信扫一扫</p>
                                </div>
                            </div>
                            <a href="https://xx.y-open.com/nebula-salesman/login" class="tool_item" target="_blank">
                                <img src="@/assets/images/header/ywypc.png" alt="" />
                                <div>
                                    <p class="font_weight_bold">业务员子系统pc端</p>
                                    <p class="marg_top_20">点击跳转</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="导入任务" :name="3">
                    <div class="ding_12">
                        <mx-table :data="importData.data">
                            <el-table-column
                                align="center"
                                prop="totalCount"
                                label="数据条数"
                                show-overflow-tooltip
                                min-width="100"
                            />
                            <el-table-column align="center" label="数据类型" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ type[row.dataType] }}
                                </template>
                            </el-table-column>
                            <el-table-column align="center" prop="creator" label="操作人" show-overflow-tooltip />
                            <el-table-column align="center" prop="createTime" label="操作时间" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ new Date(row.createTime).toLocaleDateString().replace(/\//g, "-") }}
                                    {{ new Date(row.createTime).toTimeString().split(" ")[0] }}
                                </template>
                            </el-table-column>
                        </mx-table>
                        <el-row class="pagination-container">
                            <el-pagination
                                @size-change="handleSizeChangeI"
                                @current-change="importDataChange"
                                :current-page="importData.currentPage"
                                :page-size="importData.pageSize"
                                :total="importData.total"
                                layout="total, pager, jumper"
                            />
                        </el-row>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="计算任务" :name="4">
                    <div class="ding_12">
                        <mx-table :data="queryCalData.data">
                            <el-table-column
                                align="center"
                                prop="name"
                                label="任务名称"
                                show-overflow-tooltip
                                min-width="100"
                            />
                            <el-table-column align="center" prop="time" label="时间" show-overflow-tooltip />
                            <el-table-column align="center" label="数据类型" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    <span
                                        :class="
                                            row.status == 0 || row.status == 1
                                                ? 'com_p'
                                                : row.status == 2
                                                ? 'com_s'
                                                : 'com_d'
                                        "
                                    >
                                        {{
                                            row.status == 0
                                                ? "数据准备中"
                                                : row.status == 1
                                                ? "正在执行"
                                                : row.status == 2
                                                ? "已完成"
                                                : "失败"
                                        }}
                                    </span>

                                    <el-link
                                        type="primary"
                                        v-if="row.status == 3 || row.remarks !== '--'"
                                        :underline="false"
                                        @click="errData(row)"
                                    >
                                        <svg-icon icon="iconalert-circle" />
                                    </el-link>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    <el-button
                                        v-if="row.status == '0' || row.status == '1'"
                                        @click="cancelCal(row.id)"
                                        type="primary"
                                        >取消计算</el-button
                                    >
                                </template>
                            </el-table-column>
                        </mx-table>
                        <el-row class="pagination-container">
                            <el-pagination
                                @size-change="handleSizeChangeQ"
                                @current-change="queryCalChange"
                                :current-page="queryCalData.currentPage"
                                :page-size="queryCalData.pageSize"
                                :total="queryCalData.total"
                                layout="total, pager, jumper"
                            />
                        </el-row>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="同步任务" :name="5">
                    <div class="ding_12">
                        <mx-table :data="taskSync.list">
                            <el-table-column
                                align="center"
                                prop="sn"
                                label="编号"
                                show-overflow-tooltip
                                min-width="100"
                            />
                            <el-table-column
                                align="center"
                                prop="operator"
                                label="操作人"
                                show-overflow-tooltip
                                min-width="100"
                            />
                            <el-table-column align="center" prop="status" label="状态" width="100px">
                                <template #default="{ row }">
                                    <div v-if="['失败'].includes(row.status)" class="d_flex">
                                        失败
                                        <el-link
                                            type="primary"
                                            class="marg_left"
                                            v-if="row.lastStep >= 2"
                                            @click="retryTask(row)"
                                        >
                                            重试
                                        </el-link>
                                    </div>
                                    <span v-else>{{ row.status }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="进度条" show-overflow-tooltip width="200px">
                                <template #default="{ row }">
                                    <el-progress
                                        v-if="row.status === '失败'"
                                        status="exception"
                                        :percentage="row.progress"
                                        text-inside
                                        :stroke-width="24"
                                    ></el-progress>
                                    <el-progress
                                        v-else-if="row.status === '成功'"
                                        status="success"
                                        :percentage="row.progress"
                                        text-inside
                                        :stroke-width="24"
                                    ></el-progress>
                                    <template v-else>
                                        <el-progress
                                            v-if="row.progress === 100"
                                            status="success"
                                            :percentage="row.progress"
                                            text-inside
                                            :stroke-width="24"
                                        ></el-progress>
                                        <el-progress
                                            v-else
                                            :percentage="row.progress"
                                            text-inside
                                            :stroke-width="24"
                                        ></el-progress>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="startTime"
                                label="开始时间"
                                width="100px"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                align="center"
                                prop="endTime"
                                label="结束时间"
                                width="100px"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                align="center"
                                prop="createTime"
                                label="创建时间"
                                width="100px"
                                show-overflow-tooltip
                            />
                        </mx-table>
                        <el-row class="pagination-container">
                            <el-pagination
                                @size-change="getTaskSyncListBySize"
                                @current-change="getTaskSyncList"
                                :current-page="taskSync.currentPage"
                                :page-size="taskSync.pageSize"
                                layout="total, pager, jumper"
                                :total="taskSync.total"
                            />
                        </el-row>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-drawer>
        <el-drawer v-model="message.path" :show-close="false" class="message_class">
            <el-tabs v-model="message.name" class="message_class_top" @tab-change="getMessages(null)">
                <el-tab-pane label="全部" :name="3"></el-tab-pane>
                <el-tab-pane label="已读" :name="2"></el-tab-pane>
                <el-tab-pane label="未读" :name="1"></el-tab-pane>
            </el-tabs>
            <div class="message_class_content" v-loading="loading">
                <template v-if="message.list && message.list.length">
                    <template v-for="row in message.list" :key="row.id">
                        <div class="message_class_content_column">
                            <div class="message_class_content_title">{{ row.title }}</div>
                            <div
                                class="message_class_content_content text_overflow_1"
                                v-html="row.content"
                                @click="imageEnlargement"
                            ></div>
                            <div class="message_class_content_bottom">
                                <span class="message_class_content_bottom_time">{{ row.sendTime }}</span>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div
                        style="padding-top: 20px; color: #999; font-size: 14px; text-align: center; letter-spacing: 2px"
                    >
                        暂无数据...
                    </div>
                </template>
            </div>
            <div v-if="message.list && message.list.length" class="message_class_content_pagination">
                <el-pagination
                    @size-change="handleSizeChangeM"
                    @current-change="getMessages"
                    :current-page="message.currentPage"
                    :page-size="message.pageSize"
                    :total="message.total"
                    layout="total, pager, jumper"
                />
            </div>
        </el-drawer>
        <el-dialog v-model="visible" class="renewal" :modal="false" :show-close="false" width="350px">
            <div class="text_center renewal_content">
                <p class="text_center font_size_18">微信扫一扫，联系客服续期</p>
                <img src="@/assets/images/dashboard/erCode.png" />
                <p class="text_center font_size_12">扫描二维码添加</p>
            </div>
        </el-dialog>
        <wxBind v-model="wxData.isShow"></wxBind>
        <editPwd v-model="editPwdShow" @sure="editPwdSure"></editPwd>
        <dot-list v-model="dot.show"></dot-list>
        <fox-preview-image v-model="imgModal.visible" :src="imgModal.url"></fox-preview-image>

        <div v-if="downloadIconBig" class="download-tip">
            <div class="sanjiao"></div>
            <div>
                <div>导出中</div>
                <div style="color: #999; font-size: 12px">预计花费10秒</div>
            </div>
        </div>
        <el-dialog v-model="errorData.show" :title="errorData.title" width="500px">
            <div v-html="errorData.content" style="height: 240px; text-align: left; overflow-y: scroll"></div>
            <template #footer>
                <el-button @click="errorData.show = false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { taskSyncList, taskSse, retryTask } from "@/api/task-sync/index.js";
import { list } from "@/api/individual-center/message/message.js";
import { downloadList } from "@/api/bill/data/download-export.js";
import { importRecord } from "@/api/bill/data/import-record.js";
import { switchUsing } from "@/api/finance/set-up.js";
import { browserVersion } from "@/utils/assets.js";
import { isString } from "@/utils/type";

import { wxDeleteWxBlind } from "@/api/wx/wx.js";
import { queryCalPage, overTask } from "@/api/comm.js";
import { get } from "@/utils/request.js";
import { logout } from "@/api/login.js";
import { defineComponent } from "vue";
import { Search, ArrowRight, Download, ArrowDown, Loading } from "@element-plus/icons-vue";
import editPwd from "@/views/self-center/edit-password.vue";
import dotList from "@/components/dotList.vue";
import MxPageTabs from "@/components/private/page-tabs.vue";
import wxBind from "@/components/wx-bind.vue";
import Aside from "@/components/aside.vue";
import storage from "@/utils/storage.js";

export default defineComponent({
    components: { MxPageTabs, Aside, Search, ArrowRight, Download, editPwd, ArrowDown, wxBind, dotList, Loading },
    data() {
        return {
            isShowXufei: false,
            activeIndex: "1",
            visible: false,
            visible1: false,
            wxData: { isShow: false },
            timer: null,
            pathList1: [],
            imgModal: { visible: false, url: "" },
            state1: "",
            uploadPath: false,
            dot: { isShow: false, list: [] },
            editPwdShow: false,
            Downloading: false,
            importData: { data: [], currentPage: 1, pageSize: 50, total: 0, loading: false },
            queryCalData: { data: [], currentPage: 1, pageSize: 50, total: 0, loading: false },
            download: { path: false, name: 1, list: [], currentPage: 1, pageSize: 50, total: 0, loading: false },
            message: { path: false, name: 3, list: [], currentPage: 1, pageSize: 50, total: 0, loading: false },
            taskSync: {
                path: false,
                name: 5,
                list: [],
                currentPage: 1,
                pageSize: 50,
                total: 0,
                loading: false,
                sseList: [],
            },
            expireTime: null,
            heightMax: 100,
            type: {
                0: "揽收数据原始表",
                33: "揽收数据原始表(自定义)",
                1: "派件数据原始表",
                2: "到付代收数据原始表",
                3: "用户",
                4: "客户",
                5: "商户",
                6: "网点",
                7: "报价单",
                8: "手工帐",
                9: `目的地组`,
                10: `寄件组`,
                11: `物料单价`,
                12: "特殊区域组",
                13: "账单调整导入",
                17: "入库",
                18: "出库",
                19: `系统成本`,
                20: "成本手工帐",
                23: "账单对比",
                25: "现付到付代收综合",
                26: "派费账单",
                28: "揽收数据原始表(原文件)",
                31: "成本-揽收数据导入",
                32: "成本-发货成本导入",
                30: "成本-其它成本导入",
            },
            tipsText: null,
            errorData: { show: false, title: "提示信息", content: "" },
        };
    },
    mounted() {
        this.userInfo.expireTime = this.userInfo.expireTime
            ? this.userInfo.expireTime.toString().substring(0, 10)
            : null;
        this.expireTime = this.userInfo.expireTime
            ? Math.floor((new Date(this.userInfo.expireTime).valueOf() - new Date()) / (24 * 3600 * 1000))
            : 0;
        this.$nextTick(() => {
            this.heightMax = this.$refs.element.offsetHeight;
            this.isShowXufei = true;
        });
        this.tipsText =
            browserVersion().browser == "Edge" ? "关闭'在浏览器中打开 Office 文件'选项，以提高下载速度。" : "";

        this.$watch("downloadIconBig", newVal => {
            if (newVal) {
                setTimeout(() => {
                    this.$store.commit("downloadIconBig", false);
                }, 5000);
            }
        });
    },
    methods: {
        renewNow() {
            // 立即续费
            this.$router.push({ name: "orderPlace" });
        },
        imageEnlargement(e) {
            if (e.target.nodeName == "IMG") {
                //判断点击富文本内容为img图片
                this.imgModal.url = e.target.currentSrc;
                this.imgModal.visible = true;
            }
        },
        //获取导入记录接口
        handleSizeChangeI(val) {
            //每页显示条数变化时刷新表格数据
            this.importData.pageSize = val;
            this.importData.currentPage = 1;
            this.importDataChange(1);
        },
        importDataChange(val, flag = false) {
            const ths = this;
            this.loading = true;
            this.importData.currentPage = val ? val : ths.importData.currentPage || 1;
            const data = {
                offset: this.importData.currentPage,
                limit: this.importData.pageSize,
                item: { dotId: this.$store.getters.userInfo.dotId },
            };
            importRecord(data).then(res => {
                this.loading = false;
                if (res.code === "000000") {
                    if (flag) this.$message.success("查询成功");
                    this.importData.data = res.data.list || [];
                    this.importData.total = res.data.totalRecord;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        cancelCal(id) {
            // 取消计算任务
            overTask({ id }).then(res => {
                if (res.code === "000000") {
                    this.$message.success("已取消计算");
                    this.queryCalChange();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //获取计算任务接口
        handleSizeChangeQ(val) {
            //每页显示条数变化时刷新表格数据
            this.queryCalData.pageSize = val;
            this.queryCalData.currentPage = 1;
            this.queryCalChange(1);
        },
        queryCalChange(val, flag = false) {
            const ths = this;
            this.loading = true;
            this.queryCalData.currentPage = val ? val : ths.queryCalData.currentPage || 1;
            const data = {
                pageNum: this.queryCalData.currentPage,
                pageSize: this.queryCalData.pageSize,
                dotId: this.$store.getters.userInfo.dotId,
            };
            queryCalPage(data).then(res => {
                if (res.code === "000000") {
                    if (flag) this.$message.success("查询成功");
                    this.queryCalData.data =
                        res.data.list.map(v => ({
                            ...v,
                            time: `${this.formDate(v.createTime)}-${this.formDate(v.updateTime)}`,
                        })) || [];
                    this.queryCalData.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 错误信息查看
        errData(row) {
            this.errorData.show = true;
            this.errorData.content = `${
                row.status == 2 ? "以下是忽略的已锁账运单号:<br />" : ""
            }  ${row.remarks.replaceAll("\n", "<br />")}`;
            this.errorData.title = row.status == 2 ? "错误信息" : "提示信息";
        },
        formDate(time, formate) {
            if (!time) {
                time = new Date().getTime();
            }
            var times = isString(time) ? parseInt(time) : time;
            var formDate = formate || "yyyy-MM-dd HH:mm:ss";
            var date = {
                y: new Date(times).getFullYear(),
                M: new Date(times).getMonth() + 1,
                d: new Date(times).getDate(),
                h: new Date(times).getHours(),
                m: new Date(times).getMinutes(),
                s: new Date(times).getSeconds(),
            };
            for (var key in date) {
                if (date[key] < 10) {
                    date[key] = "0" + date[key];
                }
            }
            return formDate
                .replace(/[Yy]{4}/, date.y)
                .replace(/[M]{2}/, date.M)
                .replace(/[Dd]{2}/, date.d)
                .replace(/[Hh]{2}/, date.h)
                .replace(/[m]{2}/, date.m)
                .replace(/[s]{2}/, date.s);
        },
        // 修改密码的事件
        editPwdSure(val) {
            if (val.show) {
                this.editPwdShow = false;
                this.$message.success(val.data.message);
                this.$router.push("/login");
                this.$root.reload();
            }
        },
        getImgList(url) {
            this.imgModal.visible = true;
            this.imgModal.url = url
                ? require("@/assets/images/header/ywy.png")
                : require("@/assets/images/header/boss.png");
        },
        handleHeaderMenuSelect(index) {
            this.$store.state.defaultRoute = index;
            storage.set("defaultRoute", index);
        },
        clickBindWx() {
            if (this.expireTime < 0)
                return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
            if (!this.userInfo.wxName) {
                this.wxData.isShow = true;
            }
        },
        userClick(val) {
            if (val === "editPwd") {
                if (this.expireTime < 0)
                    return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
                this.editPwdShow = true;
            } else if (val === "dotList") {
                if (this.expireTime < 0)
                    return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
                this.dot.show = true;
            } else if (val === "payList") {
                if (this.expireTime < 0)
                    return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
                this.$router.push({ name: "payList" });
            } else {
                if (this.expireTime < 0)
                    return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
                const map = {
                    personal: "/individualCenter/personalData",
                    log: "/individualCenter/log",
                    index: "/individualCenter/index",
                };
                this.$router.push(map[val]);
            }
        },
        // 登出方法
        exit_clear() {
            this.$confirm("是否退出当前账号！", "提示", { confirmButtonText: "确定", cancelButtonText: "取消" }).then(
                () => {
                    storage.remove("defaultRoute");
                    this.$store.dispatch("loginOut");
                    if (this.$store.state.websocketInstance !== null) {
                        this.$store.state.websocketInstance.close();
                        this.$store.commit("websocketInstance", null);
                    }
                    this.logoff();
                    this.$router.replace("/login");
                }
            );
        },
        route(val) {
            if (this.expireTime < 0)
                return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
            this.$router.push(val);
            this.uploadPath = false;
        },
        logoff() {
            logout().then(res => {
                if (res.code === "000000") {
                    this.$store.state.websocketInstance.close();
                    this.$store.commit("setToastCount", 2);
                    this.$store.commit("userInfo", {});
                    this.$store.commit("pageTabs", []);
                    this.$store.commit("websocketInstance", null);
                } else {
                    this.$store.commit("setToastCount", 2);
                    this.$store.commit("userInfo", {});
                    this.$store.commit("pageTabs", []);
                    this.$store.commit("websocketInstance", null);
                }
            });
        },
        //下载文件
        downloadFile(row) {
            if (row.icon) return this.$message.warning("当前文件正在下载，请不要多次点击");
            row.icon = true;
            get(`/charging/tDownloadCenter/alreadyDownload?id=${row.id}`, {}).then(res => {
                if (res.code === "000000") {
                    let url =
                        process.env.VUE_APP_AXIOS_BASE_URL !== "https://xx.y-open.com/zto"
                            ? row.path.replace("http://192.168.188.154/", "https://xx.nonewall.com:12443/")
                            : row.path;
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = row.fileName; // 下载后文件名
                    a.style.display = "none";
                    document.body.appendChild(a);
                    a.click(); // 点击下载
                    document.body.removeChild(a); // 下载完成移除元素
                    this.getDownload();
                } else {
                    this.$message.error(res.statusText);
                }

                row.icon = false;
            });
        },
        changeSearch(val) {
            if (this.expireTime < 0)
                return (
                    this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费"),
                    (this.state1 = null)
                );
            this.$router.push(this.pathList.filter(v => v.name == val)[0].url);
            if (val.indexOf("账套:") > -1) {
                if (this.$route.path == this.pathList.filter(v => v.name == val)[0].url) {
                    this.$router.go(0);
                }
                switchUsing({ id: this.pathList.filter(v => v.name == val)[0].id }).then(res => {
                    if (res.code === "000000") {
                        this.$message.success("切换成功");
                    } else {
                        this.$message.success("切换失败");
                    }
                });
            }
            if (this.$route.path == val) this.state1 = null;
        },
        focusSearch(val) {
            if (!val) return;
            let labelMenuIds = this.$store.state.userInfo.labelMenuIds
                ? this.$store.state.userInfo.labelMenuIds.split(",")
                : [];
            let arr = this.pathList.filter(v => {
                return this.state1
                    ? v.name.indexOf(this.state1) !== -1
                    : labelMenuIds.map(v => Number(v)).includes(v.id);
            });
            this.pathList1 = arr;
        },
        filterSearch(query) {
            let labelMenuIds = this.$store.state.userInfo.labelMenuIds
                ? this.$store.state.userInfo.labelMenuIds.split(",")
                : [];
            let arr = this.pathList.filter(v => {
                return query ? v.name.indexOf(query) !== -1 : labelMenuIds.map(v => Number(v)).includes(v.id);
            });
            this.pathList1 = arr;
        },
        handleSizeChangeM(val) {
            //每页显示条数变化时刷新表格数据
            this.message.pageSize = val;
            this.message.currentPage = 1;
            this.getMessages(1);
        },
        handleSizeChangeD(val) {
            //每页显示条数变化时刷新表格数据
            this.download.pageSize = val;
            this.download.currentPage = 1;
            this.getDownload(1);
        },
        getMessages(val) {
            this.message.currentPage = val || this.message.currentPage;
            let isRead = [2].includes(this.message.name) ? 0 : [3].includes(this.message.name) ? "" : this.message.name;
            list({
                limit: this.message.pageSize,
                offset: this.message.currentPage,
                item: { title: "", type: null, isRead: isRead, dotId: this.userInfo.dotId },
            }).then(res => {
                this.loading = false;
                if (res.code === "000000") {
                    this.message.list = res.data.list || [];
                    this.message.total = res.data.totalRecord;
                } else {
                    this.message.list = [];
                    this.message.total = 0;
                }
            });
        },
        showDrawer(res) {
            if (this.expireTime < 0)
                return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
            if (res == "messages") {
                this.message.path = true;
                this.getMessages();
            } else {
                this.download.path = true;
                this.getDownload();
                this.importDataChange();
                this.queryCalChange();
                this.getMessages(null);
            }
        },
        deleteWxBlind(row) {
            if (this.expireTime < 0)
                return this.$message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
            this.$confirm("此操作将解除绑定微信, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                wxDeleteWxBlind(row.userId).then(res => {
                    if (res.code == "000000") {
                        this.$message({ type: "success", message: "解除绑定成功" });
                        this.$store.state.userInfo.isBlindWx = "0";
                        this.$store.state.userInfo.wxName = "";
                    } else {
                        this.$message.error(res.message);
                    }
                });
            });
        },
        getDownload(val) {
            this.download.currentPage = val || this.download.currentPage;
            downloadList({
                limit: this.download.pageSize,
                offset: this.download.currentPage,
                item: { dotId: this.$store.state.userInfo.dotId, templateName: "", startDate: "", endDate: "" },
            }).then(res => {
                this.loading = false;
                if (res.code === "000000") {
                    this.download.list = res.data.list.map(v => {
                        return { ...v, icon: false };
                    });
                    this.download.total = res.data.totalRecord;
                } else {
                    this.download.list = [];
                    this.download.total = 0;
                }
            });
        },

        // 同步任务模块
        retryTask(row) {
            const data = { dotId: this.$store.state.userInfo.dotId, sn: row.sn, brand: row.brand };
            retryTask(data).then(res => {
                if (res.code === "000000") {
                    this.createSSETask(row.sn);
                    row.status = "待处理";
                    row.progress = 0;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        createSSETask(sn) {
            const url = taskSse(sn);
            const sse = new EventSource(url, { withCredentials: true });
            sse.addEventListener("message", e => {
                const data = JSON.parse(e.data);
                if (data.end) sse.close();
                this.taskSync.list = this.taskSync.list.map(v => {
                    if (v.sn === data.sn) {
                        const progress = typeof data.msg === "string" ? parseFloat(data.msg) : data.msg;
                        let status = v.status;
                        if (progress === 100) {
                            status = "成功";
                        } else if (progress === -1) {
                            status = "失败";
                        }
                        return { ...v, progress: progress, status: status };
                    }
                    return v;
                });
            });
            sse.onerror = () => {
                sse.close();
            };
            this.taskSync.sseList.push(sse);
        },
        getTaskSyncList(val) {
            const data = {
                pageSize: this.taskSync.pageSize,
                pageNum: val || this.taskSync.currentPage,
                dotId: this.$store.state.userInfo.dotId,
            };
            taskSyncList(data).then(res => {
                if (res.code === "000000") {
                    const respData = res.data.list.map(v => {
                        if (["失败", "成功"].includes(v.status)) {
                            return { ...v, progress: 100 };
                        } else {
                            this.createSSETask(v.sn);
                            return { ...v, progress: 0 };
                        }
                    });
                    this.taskSync.list = respData;
                    this.taskSync.total = res.data.totalRecord;
                }
            });
        },
        getTaskSyncListBySize(val) {
            this.taskSync.pageSize = val;
            this.getTaskSyncList();
        },
        handleTabChange(val) {
            if (val === 5) {
                this.getTaskSyncList(1);
            } else if (val == 1) {
                this.getDownload();
                this.taskSync.sseList.forEach(v => v.close());
            } else if (val == 3) {
                this.importDataChange();
                this.taskSync.sseList.forEach(v => v.close());
            } else if (val == 4) {
                this.queryCalChange();
                this.taskSync.sseList.forEach(v => v.close());
            } else {
                this.taskSync.sseList.forEach(v => v.close());
            }
        },
    },
    computed: {
        userInfo: function () {
            return this.$store.state.userInfo;
        },
        messages() {
            return this.$store.getters.messages;
        },
        pathList() {
            return this.$store.state.pathList;
        },
        pageTabs: {
            get: function () {
                let target = [];
                target = this.$store.state.pageTabs;
                return target;
            },
        },
        downloadIconBig: function () {
            return this.$store.state.downloadIconBig;
        },
    },
    watch: {
        userInfo: {
            handler() {
                if (this.userInfo.userId) {
                    this.userInfo.expireTime = this.userInfo.expireTime
                        ? this.userInfo.expireTime.toString().substring(0, 10)
                        : null;
                    this.expireTime = this.userInfo.expireTime
                        ? Math.floor((new Date(this.userInfo.expireTime).valueOf() - new Date()) / (24 * 3600 * 1000))
                        : 0;
                }
            },
            deep: true,
        },
        $route: {
            handler() {
                this.state1 = "";
            },
            deep: true,
        },
        "$store.state.task": {
            handler(val) {
                if (val.stop === true) {
                    this.showDrawer("download");
                    this.download.name = 4;
                    this.$store.commit(`task`, {});
                }
            },
            deep: true,
        },
    },
});
</script>

<style lang="scss">
.renewal {
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 0;
    }
    &_content {
        width: 100%;
        padding: 30px 20px;
        background-image: url("@/assets/images/dashboard/beijing_1.png");
        background-size: 100% 100%;
        background-attachment: fixed;
        height: 420px;
        width: 350px;
        text-align: center;
        img {
            width: 72%;
            margin: 10%;
            border: 4px #0082ff solid;
            border-radius: 6px;
        }
        p:nth-child(1) {
            font-weight: bold;
            color: #1d2129;
            line-height: 39px;
        }
    }
}
</style>
<style scoped lang="scss">
.header_box {
    font-size: 14px;
    position: relative;
    min-height: 100px;
    // max-height: 140px;
    width: 100%;
    color: #666666;
    background-color: #ffffff;
    border-bottom: 1px solid #f2f2f2;
    // overflow: hidden;
    .download-tip {
        position: fixed;
        right: 11vw;
        top: 8vh;
        color: #000;
        padding: 6px 30px;
        box-shadow: 5px 5px 10px 5px #ccc;
        background-color: #fff;
        z-index: 9999;
        .sanjiao {
            width: 0;
            height: 0;
            border-bottom: 5px solid #fff;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            position: absolute;
            left: 50%;
            top: -5px;
            transform: translateX(-50%);
        }
    }
}
.logo-box {
    display: inline-block;
    height: 30px;
    width: 30px;
    vertical-align: -14%;
}
.left_top {
    display: flex;
    align-items: center;
    height: 100%;
    width: 50%;
    justify-content: space-between;
    img:nth-child(1) {
        width: 5%;
    }
    img:nth-child(2) {
        width: 28%;
        position: relative;
    }
}
.logo {
    display: inline-block;
    height: 30px;
    width: 30px;
    background: url("../assets/images/logo.svg") center -19px no-repeat;
    color: #409eff;
} /*搜索input框 */
:deep(.el-input__wrapper) {
    border-radius: 95px;
    border: 0;
    box-shadow: 0 0 0 0px;
}
.menu-box {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 1%;
    background: url("@/assets/images/menu/bg_1.png") center no-repeat;
    background-size: 100% 100%;
    height: 7.4vh;
    min-height: 58px;
    max-height: 80px;
    :deep(.el-select) {
        width: 16vw;
        min-width: 200px;
    }
    :deep(.el-select__wrapper) {
        border-radius: 20px;
    }
    :deep(.el-input__suffix-inner) {
        display: none;
    }
}
.menu-items {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    &.cursor-default {
        cursor: default;
    }
}

.message-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.message-list {
    border: 1px solid #f1f1f1;
    border-width: 1px 0;
    font-size: 12px;
    max-height: 350px;
    overflow-y: auto;
    padding-right: 5px;
    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        cursor: pointer;
        &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 6px;
        }
        &-title {
            font-size: 14px;
            font-weight: 500;
            color: #000;
        }
        &-time {
            flex: 0 0 90px;
            text-align: right;
            color: #999;
        }
        &-avatar {
            flex: 0 0 52px;
            align-self: flex-start;
        }
    }
}
.message-foot {
    display: flex;
    justify-content: center;
    align-items: center;
    &-btn {
        color: #409eff;
        margin-top: 14px;
        cursor: pointer;
    }
}
.upload {
    &_class {
        display: flex;
        justify-content: space-between;
        &_item {
            width: 24%;
            text-align: center;
            position: relative;
            div {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 11px;
                border: 1px dashed #979797;
                background: linear-gradient(180deg, rgba(219, 237, 254, 0) 0%, #d8ebfe 100%);
            }
        }
    }
}
:deep(.message_class) {
    color: #000;
    .el-tabs__item {
        font-size: 18px;
    }
    .is-active {
        font-weight: bold;
        color: #1d2129;
    }
    .el-drawer__body {
        padding: 0;
    }
    .el-tabs__item {
        height: 60px;
        line-height: 60px;
    }
    .el-drawer__header {
        display: none;
    }
    .el-tabs__nav-wrap {
        padding: 0 4%;
    }
    .el-tabs__active-bar {
        height: 4px;

        background: #0082ff;
    }
    .message_class_content {
        &_pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            overflow-x: auto;
            margin: 6px 0;
        }
        padding: 0 4%;
        max-height: 84vh;
        overflow-y: auto;
        &_column {
            padding: 0 0 20px 0;
            border-bottom: 1px solid #e5e6e8;
            margin: 10px 0 0 0;
        }
        &_title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            margin: 0 0 8px 0;
        }
        &_content {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin: 10px 0 0 0;
            img {
                max-width: 100%;
            }
        }
        &_bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 10px 0 0 0;
            &_time {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #86909c;
                line-height: 20px;
            }
        }
    }
}
:deep(.download_c) {
    width: 600px !important;
    color: #000;
    .el-tabs__item {
        font-size: 18px;
    }
    .el-tabs__item {
        height: 60px;
        line-height: 60px;
    }
    .is-active {
        font-weight: bold;
        color: #1d2129;
    }
    .el-drawer__body {
        padding: 0;
    }
    .el-drawer__header {
        display: none;
    }
    .el-tabs__nav-wrap {
        padding: 0 4%;
    }
    .el-tabs__active-bar {
        height: 4px;
        background: #0082ff;
    }
    .downloadContent {
        width: 100%;
        padding: 20px;
        max-height: 84vh;
        overflow-y: auto;
        position: relative;
        &_pos {
            position: absolute;
            color: #979797;
            top: 0;
            font-size: 12px;
        }
        &_item:hover {
            box-shadow: 0 0 12px 0 rgba(0, 102, 255, 0.2);
        }
        .downloaded {
            border: 1px solid #979797;
        }
        .downloaded .downloadContent_item_right {
            background: #979797;
        }
        &_item {
            width: 100%;
            height: 148px;
            background: #f7f8fa;
            border: 1px solid #0082ff;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &_left {
                width: 70%;
                padding: 24px 36px 24px 24px;
                overflow: hidden;
                height: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                p {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #86909c;
                }
                &_title {
                    font-size: 18px !important;
                    font-weight: 500 !important;
                    color: #1d2129 !important;
                }
            }
            &_right {
                width: 15%;
                height: 100%;
                background: #0082ff;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                cursor: pointer;
                cursor: hand;
            }
        }
    }
}
.personal_center {
    &_top {
        width: 100%;
        padding: 4%;
        height: 114px;
        background: linear-gradient(180deg, #d6edff 0%, rgba(214, 237, 255, 0) 100%);
    }
    &_bottom {
        display: inline-block;
        border-top: 1px solid #e5e6e8;
        width: 100%;
        text-align: center;
        font-weight: bold;
        padding: 16px 0;
        margin: 20px 0 0 0;
    }
    &_item {
        width: 100%;
        height: 46px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_item:hover {
        color: #0082ff;
        background: #e8f8ff;
    }
}
.tool {
    display: flex;
    padding: 20px;
    &_left {
        width: 48%;
    }
    &_right {
        width: 48%;
        margin: 0 0 0 4%;
    }
    &_item {
        width: 100%;
        height: 120px;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        border-radius: 10px;
        img {
            width: 60px;
            margin: 0 0 0 16px;
        }
        div {
            margin: 0 0 0 12px;
        }
        & + & {
            margin: 10px 0 0 0;
        }
    }
}
.com {
    &_p:before {
        content: "";
        width: 8px;
        height: 8px;
        margin: 0 8px 0 0;
        display: inline-block;
        border-radius: 50%;
        background: #0082ff;
    }
    &_d:before {
        content: "";
        width: 8px;
        height: 8px;
        margin: 0 8px 0 0;
        display: inline-block;
        border-radius: 50%;
        background: #f30642;
    }
    &_s:before {
        content: "";
        width: 8px;
        height: 8px;
        margin: 0 8px 0 0;
        display: inline-block;
        border-radius: 50%;
        background: #2ede0f;
    }
}
.xufei-wrap {
    width: 100%;
    height: 40px;
    background: #fffbe6;
    border: 1px solid #ffe58f;
    position: relative;
    &-con {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-close {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
</style>
