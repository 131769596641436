<template>
    <el-dialog
        :title="listDot && listDot.length ? '选择网点' : '切换网点'"
        v-model="visible"
        width="500px"
        @close="closeEdit"
    >
        <div v-if="list && list.length" class="item_content">
            <div
                class="allW_100 item_list cursor_pointer"
                v-for="(item, i) in list"
                @click="sureClick(i)"
                :class="{ item_selected: i === index }"
                :style="`pointer-events: ${i === index && 'none'}; `"
                :key="i"
            >
                <span><img src="@/assets/images/gs.png" alt="" /> {{ item.dotName }}</span>
                <span class="d_flex d_flex_aItc">
                    <span
                        class="font_size_12 item_list_text"
                        :class="{ fadeNum: i === index }"
                        style="line-height: 17px"
                    >
                        请双击当前网点进行切换</span
                    >
                    <el-icon><ArrowRight /></el-icon>
                </span>
            </div>
        </div>
        <div class="text_center" v-else>
            <el-icon class="text_danger" style="font-size: 100px"><WarningFilled /></el-icon>
            <p style="font-size: 16px; font-weight: 400; color: #1d2129; line-height: 32px">
                {{ message || "当前账号暂无关联网点" }}
            </p>
        </div>
    </el-dialog>
</template>
<script>
import { ArrowRight, WarningFilled } from "@element-plus/icons-vue";
import { getDotId, getDotSwi, wxLogin } from "@/api/login.js";
import { isFirstLogin, searchMenu } from "@/api/dashboard.js";
import { sendChannelMessage } from "@/utils/utils.js";
import { getUsers } from "@/api/basic/user/user.js";
import { bookList } from "@/api/finance/set-up.js";
import { getElMs } from "@/utils/utils";

export default {
    components: { ArrowRight, WarningFilled },
    data() {
        return {
            list: [],
            index: null,
            message: null,
            visible: false,
        };
    },
    props: {
        modelValue: { type: Boolean, default: false },
        listDot: { type: Array, default: () => [] },
        code: { type: String },
        callback: Function,
    },
    methods: {
        closeEdit() {
            this.index = null;
            this.visible = false;
        },
        closeChannelEvent() {
            sendChannelMessage("zto-main", { type: "close" });
            sendChannelMessage("zto-module", { type: "close" });
        },
        isFirstLogin(dotId) {
            isFirstLogin(dotId).then(res => {
                if (res.code === "000000") {
                    this.$store.commit("isFirstLogin", !res.data);
                } else {
                    this.$store.commit("isFirstLogin", false);
                }
            });
        },
        sureClick(ind) {
            this.index = ind;
            this.sure();
        },
        sure() {
            if (this.listDot && this.listDot.length) {
                if (![null].includes(this.index)) {
                    wxLogin({ dotId: this.list[this.index].dotId, code: this.code }).then(res => {
                        if (res.code === "000000") {
                            this.closeEdit();
                            getElMs(this.code ? "登录成功" : "网点已切换。") > 0
                                ? null
                                : this.$message.success(this.code ? "登录成功" : "网点已切换。");
                            let resData = res.data;
                            this.$store.commit(`cacheList`, []);
                            this.$store.commit(`pageTabs`, []);
                            this.closeChannelEvent();
                            this.$store.commit("token", res.data.loginToken);
                            this.$store.dispatch("getAuth", true);
                            this.isFirstLogin(res.data.dotId);
                            searchMenu().then(res => {
                                if (res.code === "000000") {
                                    let arr = [...res.data.filter(v => v.url && v.hasPermission)];
                                    bookList().then(res => {
                                        if (res.code === "000000") {
                                            let arr1 = res.data.list.map(v => ({
                                                id: v.id,
                                                url: "/finance/set-up/account-cover",
                                                name: "账套:" + v.compName,
                                            }));
                                            this.$store.commit("pathList", [...arr, ...arr1]);
                                        } else {
                                            let arr = [];
                                            this.$store.commit("pathList", arr);
                                        }
                                    });
                                } else {
                                    let arr = [];
                                    this.$store.commit("pathList", arr);
                                }
                            });
                            getUsers({
                                limit: 1,
                                offset: 1,
                                item: {
                                    name: resData.name,
                                    status: "",
                                    remark1: "",
                                    remark2: "",
                                    code: "",
                                    dotId: resData.dotId,
                                },
                            }).then(res => {
                                if (res.code == "000000") {
                                    resData = { ...resData, code: res.data.list[0].code };
                                }
                                this.$store.commit("userInfo", resData);
                                this.$store.dispatch("getAuth", true);
                            });
                            this.$router.push("/");
                            if (this.callback) {
                                this.callback();
                            }
                        } else {
                            this.message = res.message;
                        }
                    });
                } else {
                    this.$message.error("当前并未选择网点");
                }
            } else {
                if (![null].includes(this.index)) {
                    getDotSwi({ dotId: this.list[this.index].dotId }).then(res => {
                        if (res.code === "000000") {
                            this.closeEdit();
                            getElMs(this.code ? "登录成功" : "网点已切换。") > 0
                                ? null
                                : this.$message.success(this.code ? "登录成功" : "网点已切换。");
                            let resData = res.data;
                            this.$store.commit(`cacheList`, []);
                            this.$store.commit(`pageTabs`, []);
                            this.closeChannelEvent();
                            this.$store.commit("token", res.data.loginToken);
                            this.isFirstLogin(res.data.dotId);
                            searchMenu().then(res => {
                                if (res.code === "000000") {
                                    let arr = [...res.data.filter(v => v.url && v.hasPermission)];
                                    bookList().then(res => {
                                        if (res.code === "000000") {
                                            let arr1 = res.data.list.map(v => ({
                                                id: v.id,
                                                url: "/finance/set-up/account-cover",
                                                name: "账套:" + v.compName,
                                            }));
                                            this.$store.commit("pathList", [...arr, ...arr1]);
                                        } else {
                                            let arr = [];
                                            this.$store.commit("pathList", arr);
                                        }
                                    });
                                } else {
                                    let arr = [];
                                    this.$store.commit("pathList", arr);
                                }
                            });
                            getUsers({
                                limit: 1,
                                offset: 1,
                                item: {
                                    name: resData.name,
                                    status: "",
                                    remark1: "",
                                    remark2: "",
                                    code: "",
                                    dotId: resData.dotId,
                                },
                            }).then(res => {
                                if (res.code == "000000") {
                                    resData = { ...resData, code: res.data.list[0].code };
                                }
                                this.$store.commit("userInfo", resData);
                                this.$store.dispatch("getAuth", true);
                            });
                            this.$router.push("/");
                            if (this.callback) {
                                this.callback();
                            }
                        } else {
                            this.$message.error(res.message);
                            this.message = res.message;
                        }
                    });
                } else {
                    this.$message.error("当前并未选择网点");
                }
            }
        },
        getDotCode() {
            if (this.listDot && this.listDot.length) {
                this.list = this.listDot;
            } else {
                getDotId().then(res => {
                    if (res.code === "000000") {
                        this.list = res.data;
                    } else {
                        this.message = res.message;
                    }
                });
            }
        },
    },
    watch: {
        modelValue: {
            handler(val) {
                this.visible = val;
                if (val) this.getDotCode();
            },
            immediate: true,
        },
        visible: {
            handler(val) {
                this.$emit("update:modelValue", !!val);
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="scss">
.item {
    &_list {
        width: 100%;
        height: 56px;
        line-height: 56px;
        display: flex;
        justify-content: space-between;
        background-color: #f6f8fa;
        border: 1px solid #fff;
        border-radius: 10px;
        align-items: center;
        padding: 0 10px;
        font-size: 16px;
        &_text {
            opacity: 0;
        }
        img {
            width: 24px;
        }
        & + & {
            margin: 10px 0 0 0;
        }
        .fadeNum {
            animation: fadeNum 2.5s 1 forwards;
        }
        @keyframes fadeNum {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    &_selected {
        background-color: #e8f8ff;
        color: #0082ff;
        border: 1px solid #0082ff;
    }
    &_content {
        max-height: 50vh;
        overflow-y: auto;
    }
}
</style>
