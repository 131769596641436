/**
 * 判断参数的类型
 * @param {*} arg 被判断的参数
 * @returns {String} String类型
 */
export function typeOf(arg) {
    if (Number.isNaN(arg)) {
        return "NaN";
    } else {
        const iniType = Object.prototype.toString.call(arg);
        return iniType.replace(/^(\[object\s)([a-zA-Z]+)\]$/, "$2");
    }
}

/**
 * 是否是Map类型
 * @param {unknown} arg
 * @returns { Boolean } 布尔类型
 */
export function isMap(arg) {
    return typeOf(arg) === "Map";
}

/**
 * 是否是Set类型
 * @param {unknown} arg
 * @returns { Boolean } 布尔类型
 */
export function isSet(arg) {
    return typeOf(arg) === "Set";
}

/**
 * 是否是Array类型
 * @param {unknown} arg
 * @returns { Boolean } 布尔类型
 */
export function isArray(arg) {
    return typeOf(arg) === "Array";
}

/**
 * 是否是Object类型
 * @param {unknown} arg
 * @returns { Boolean } 布尔类型
 */
export function isObject(arg) {
    return typeOf(arg) === "Object";
}

/**
 * 是否是NaN类型
 * @param {unknown} arg
 * @returns { Boolean } 布尔类型
 */
export function isNaN(arg) {
    return typeOf(arg) === "NaN";
}

/**
 * 是否是String类型
 * @param {unknown} arg
 * @returns { Boolean } 布尔类型
 */
export function isString(arg) {
    return typeOf(arg) === "String";
}

/**
 * 是否是Number类型
 * @param {unknown} arg
 * @returns { Boolean } 布尔类型
 */
export function isNumber(arg) {
    return typeOf(arg) === "Number";
}

/**
 * @name isFunction
 * @description 是否是Function类型
 * @param {any} any 要判断的参数
 * @exports isFunction
 * @returns True/False
 */
export const isFunction = any => {
    return typeOf(any) === "Function";
};
