<template>
    <el-popover
        placement="bottom-start"
        :show-arrow="false"
        ref="elPopover"
        trigger="hover"
        popper-class="sa-menu"
        v-if="expireTime > -1"
    >
        <template #reference>
            <img
                src="@/assets/images/menu/menu_1.png"
                class="cursor_pointer step_1 cursor_pointer hover_ebf0f62f border_radius_2 ding_4 ding_2"
            />
        </template>
        <div class="sa-menu-content d_flex">
            <div class="sa-menu-left">
                <div
                    v-for="(item, index) in menus"
                    class="sa-menu-left-title d_flex d_flex_aItc cursor_pointer"
                    @mouseover="subMenuClick(index)"
                    :class="(curMenuIndex || 0) == index ? 'title_color' : ''"
                    @click="subMenuClick(index)"
                    :key="index"
                >
                    <svg-icon
                        :icon="item.icon"
                        class="font_size_16 marg_right_24"
                        :class="(curMenuIndex || 0) == index ? 'color: #0082ff' : ''"
                    />
                    <span>{{ item.name }}</span>
                </div>
            </div>
            <div class="sa-menu-right" v-if="menus && menus.length">
                <div v-for="(item, index) in menus[curMenuIndex || 0].children" class="sa-menu-right-item" :key="index">
                    <div class="sa-menu-right-title">{{ item.name }}</div>
                    <div
                        class="d_flex d_flex_aItc"
                        :class="{
                            'sa-menu-right-content': item1.hasPermission,
                            'sa-menu-right-dis': !item1.hasPermission,
                        }"
                        v-show="item.children"
                        v-for="(item1, index1) in item.children"
                        :key="index1"
                    >
                        <div
                            class="d_flex d_flex_between allW_100 cursor_pointer"
                            :class="{ 'sa-menu-right-content-top': item1.hasPermission }"
                        >
                            <span class="marg_right_8 text_overflow_1" @click="handleAsideClick(item1, item.url)">{{
                                item1.name
                            }}</span>
                            <img
                                src="@/assets/images/menu/collection.png"
                                class="sa-menu-right-content-top-img"
                                v-if="item1.menusPng"
                                @click="userCollect(item1, 'del')"
                            />
                            <img
                                src="@/assets/images/menu/wCollection.png"
                                class="sa-menu-right-content-top-imgs"
                                v-else
                                @click="userCollect(item1, 'add')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-popover>
    <el-tooltip content="当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费" v-else>
        <img src="@/assets/images/menu/menu_1.png" class="cursor_pointer step_1" />
    </el-tooltip>
</template>
<script>
import { userUpdCollect } from "@/api/dashboard.js";
import { getElMs } from "@/utils/utils";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
const iconMap = {
    basic: "menu-basic",
    bill: "menu-settlement",
    cost: "menu-cost",
    finance: "menu-accounting",
    materiel: "menu-materiel",
    "report-form": "menu-report",
    "financial-management": "menu-report",
    reconciliationHQ: "menu-reconciliation",
    claims: "menu-claims",
    employee: "menu-employee",
    hq: "iconfile-document-box",
};

export default {
    data() {
        return {
            activeIndex: this.$route.path,
            curMenuIndex: 0,
            expireTime: null,
        };
    },
    props: {
        heightMax: { type: Number, default: Number },
    },
    mounted() {
        this.expireTime = this.$store.state.userInfo.expireTime
            ? Math.floor((new Date(this.$store.state.userInfo.expireTime).valueOf() - new Date()) / (24 * 3600 * 1000))
            : 0;
        if (this.$store.state.token && !this.$store.state.novice) this.setGuide();
    },
    methods: {
        setGuide() {
            this.$nextTick(function () {
                if (this.$store.state.userInfo.loginToken) {
                    this.driver = new Driver({
                        doneBtnText: "完成", // 结束按钮的文字
                        animate: true, // 动画
                        opacity: 0.5,
                        allowClose: false,
                        stageBackground: "#0A8DFF", // 突出显示元素的背景颜色
                        closeBtnText: "知道了", // 关闭按钮的文字
                        onDeselected: () => this.$store.commit("novice", 1),
                    });
                    this.driver.defineSteps([
                        {
                            element: ".step_1",
                            popover: {
                                className: "step_1",
                                title: '<h4 class="head">菜单变更</h4>',
                                description: '<div class="detail">位置都移到这里了</div>',
                                position: "bottom",
                            },
                        },
                    ]);
                    this.driver.start();
                }
            });
        },
        handleAsideClick(val, ind) {
            if (!val.hasPermission)
                return getElMs("菜单无权限，请联系管理员添加") > 0
                    ? null
                    : this.$message.error("菜单无权限，请联系管理员添加");
            if (!["materiel"].includes(ind)) {
                this.$router.push({ path: val.url });
                this.$refs.elPopover.hide();
            } else {
                this.$router.push({ path: val.url });
                this.$refs.elPopover.hide();
            }
        },
        userCollect(val, type) {
            let arr = this.userInfo.labelMenuIds ? this.userInfo.labelMenuIds.split(",") : null;
            let labelMenuIds = arr
                ? type == "add"
                    ? [...arr, val.id].join(",")
                    : [...arr.filter(v => v !== val.id)].join(",")
                : [val.id].join(",");
            if (labelMenuIds.split(",").length > 19) {
                this.$message.error("收藏标签数量不可超过20个");
            } else {
                userUpdCollect({ labelMenuIds: labelMenuIds }).then(res => {
                    this.$store.commit("userInfo", { ...this.userInfo, labelMenuIds: labelMenuIds });
                    if (res.code === "000000") {
                        this.$message.success(type == "add" ? "收藏成功" : "取消收藏成功");
                    } else {
                        this.$message.error(type == "add" ? "收藏失败" : "取消收藏");
                    }
                });
            }
        },
        subMenuClick(id) {
            if (this.curMenuSub === id) {
                this.curMenuIndex = null;
            } else {
                this.curMenuIndex = id || null;
            }
        },
        childRen(list) {
            return list.map(v => {
                let menusPng = this.userInfo.labelMenuIds
                    ? this.userInfo.labelMenuIds.split(",").includes(v.id)
                        ? true
                        : null
                    : null;
                v.children && v.children.length ? (v.children = this.childRen(v.children)) : "";
                return { ...v, children: v.children && v.children.length ? v.children : "", menusPng: menusPng };
            });
        },
    },
    computed: {
        menus() {
            let menu = [];
            const allModule = this.$store.state.menus;
            menu = allModule.map(v => {
                return { ...v, icon: iconMap[v.url] };
            });
            menu = menu.filter(v => !v.type);
            return this.childRen(menu);
        },
        path() {
            return this.$route.path;
        },
        userInfo: function () {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        path() {
            this.activeIndex = this.$route.path;
        },
        heightMax() {
            document.getElementsByClassName("sa-menu")[0].style.setProperty("--heightMax", this.heightMax + "px");
        },
    },
};
</script>
<style lang="scss">
.sa-menu {
    --heightMax: 13vh;
    width: 100% !important;
    height: 77vh !important;
    border-bottom: 1px solid #e5e6e8;
    z-index: 2058 !important;
    top: var(--heightMax) !important;
    transform: translate3d(0px, 0px, 0px) !important;
    background-color: #fff !important;
    padding: 0 !important;
    max-width: 100vw !important;
    left: 0 !important;
    color: #1d2129;
    display: flex;
    &-top {
        position: absolute;
        width: 100px;
        height: 6vh;
        top: -6vh;
        min-height: 50px;
    }
    &-left {
        height: 100%;
        min-width: 200px;
        border-right: 1px solid #e5e6e8;
        &-title {
            width: 100%;
            height: 5.3% !important;
            min-height: 52px;
            font-size: 16px;
            padding: 10px 27px;
            font-weight: 600;
            color: #1d2129;
        }
    }
    &-right {
        height: 100%;
        padding: 20px;
        display: flex;
        width: 100%;
        overflow-y: auto;
        width: 100%;
        &-item {
            min-width: 180px;
            max-width: 220px;
        }
        &-title {
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            color: #1d2129;
            text-indent: 16px;
            margin: 0 0 24px 0;
            &-1 {
                font-size: 16px;
                padding: 0 0 0 20px;
                font-weight: bold;
            }
        }
        &-dis {
            color: #b7b7b7;
            height: 18px;
            text-indent: 16px;
            font-size: 14px;
            line-height: 32px;
            margin: 24px 0;
            img {
                height: 14px;
                margin: 8px 16px 0 0;
            }
        }
        &-content {
            color: #4e5969;
            height: 18px;
            text-indent: 16px;
            font-size: 14px;
            line-height: 32px;
            margin: 24px 0;
            &-top {
                img {
                    height: 14px;
                    margin: 8px 16px 0 0;
                }
                &-imgs {
                    height: 14px;
                    margin: 8px 16px 0 0;
                    display: none;
                }
                span {
                    position: relative;
                }
            }
            &-top:hover &-top-imgs {
                display: inline-block;
            }
            &-top:hover {
                color: #0082ff;
                background: #e8f8ff;
            }
            &-top:hover span:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 16px;
                width: 100%;
                height: 2px;
                background-color: #409eff;
                border-radius: 2px;
            }
        }
    }
}
.title {
    &_color {
        position: relative;
        color: #0082ff;
        background: #e8f8ff;
    }
    &_color:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: #409eff;
        border-radius: 2px;
    }
}
.driver-close-only-btn {
    margin-left: 30% !important;
    color: #fff !important;
    background-color: #409eff !important;
    text-shadow: 1px 1px 0 #409eff !important;
    border-color: #409eff !important;
    display: inline-block !important;
    line-height: 1 !important;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none !important;
    text-align: center !important;
    box-sizing: border-box !important;
    outline: none !important;
    transition: 0.1s !important;
    font-weight: 400 !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 6px 16px !important;
    font-size: 14px !important;
    border-radius: 4px !important;
}
</style>
